<template>
    <div class="home">
      <!-- <div class="top"></div> -->
      <!-- <HowplayBtn /> -->
      <!-- <quick-bet :lastBetData="lastBetData" @quickBet="quickBet" v-if="lastBetData.value"></quick-bet> -->
      
      <div class="gameWrapper flex column">
        <div class="gameBox" v-for="gr,i in gameRewards" :key="i">
          <game :active="i==$store.state.currentGameId" :reward="gr" :id="i" />
        </div>
      </div>
      
    </div>
</template>
<script>
// import ChooseBtn from "../components/chooseBtn.vue"
// import BetCoin from "../components/BetCoin.vue"
// import QuickBet from "../components/QuickBet.vue"
// import Avatar from "../components/Avatar.vue"
// import HowplayBtn from '../components/HowplayBtn.vue';
import Game from '../components/Game.vue';
import config from "../config"
export default {
  name: 'Home',
  data(){
    return {
      gameRewards: this.$store.state.gameRewards,
      playBoxVisible: false,
      tronWeb: null,
      currency: 'usdt',
      // samount: 0,
      approveModalVisable:false,
      betAgainModalVisable:true,
      approveAmounts: 2000,
      approvedAmount: 0,
      zxValue: 0,
      amountStep: 1,
      // presetAmount: config._GAME_PRESET_AMOUNT,
      // betColors: config._BET_COLORS,
      // minAmount: 10,
      maxAmount: 200,
      hsContract:{},
      usdtToken:{},
      bets:[],
      gid: 2,
      games:[],
      showLogin: false,
      histories:[],
      roads:[[],[],[],[]],
      lastBetData:{},
    }
  },
  components:{
    // HowplayBtn,QuickBet
    Game,
  },
  computed:{
    // sendamount(){
    //   let samount = 0;
    //   if(this.bets.length==0){
    //     return 0;
    //   }
    //   this.bets.forEach((bet)=>{
    //     samount+=bet.value;
    //   })
    //   if(this.gid==2){
    //     //baccarat add decimal
    //     return (this.zxValue==0)? Math.floor(Math.abs(samount))+0.2:Math.floor(Math.abs(samount))+0.1;
    //   }else if(this.gid==1){
    //     if(this.zxValue==0){
    //       //check even,auto generate even if samount is odd
    //       if(samount >= this.maxAmount){
    //         return Math.abs(samount) - (Math.abs(samount) % 2?1:2);
    //       }
    //       return Math.abs(samount) % 2 + Math.abs(samount);
    //     }else{
    //       //check odd,auto generate odd if samount is even
    //       if(samount >= this.maxAmount){
    //         return Math.abs(samount) - (1-Math.abs(samount) % 2);
    //       }
    //       return (1-Math.abs(samount) % 2) + Math.abs(samount);
    //     }
    //   }else{
    //     return Math.abs(samount);
    //   }
    // },
    // mostEarn(){
    //   // let _samount = Math.floor(Math.abs(this.samount));
    //   if(this.bets.length == 0){
    //     return 0;
    //   }
    //   return this.sendamount * this.gameRewards[this.gid][0]/100; 
    // }
  },
  mounted(){
    this.$store.commit("setGameRewards", config._DEFAULT_GAME_REWARDS_);
    let _this = this;
    let obj = setInterval(async ()=>{
      if (window.walletAddress && window.HASH_CONTRACT && window.USDT_TOKEN) {
          clearInterval(obj)
          //debug 
          // window.walletAddress = "TYALdfFALWQsAUuXHvi9kmkU7ZYNEdFprc";
          //debugEND
          // window.Echo.channel('iwina.'+window.walletAddress)
          // .listen('SomeoneWin', (e) => {
          //     console.log(e.winner);
          //     _this.$store.commit('pushRoad', e.winner);
              
          //     if(e.winner.win){
          //       _this.someoneWin(e.winner);
          //     }
          // });
          window.HASH_CONTRACT.getGameRewards().call().then((res)=>{
            _this.$store.commit("setGameRewards", res);
          });
          
          // _this.getLastBet();
          // window.HASH_CONTRACT.game().call().then((res)=>{
          //   let _minAmount = typeof(res.minAmount)=='object' ? res.minAmount.toNumber():res.minAmount;
          //   let _maxAmount = typeof(res.maxAmount)=='object' ? res.maxAmount.toNumber():res.maxAmount;

          //   _this.minAmount = _minAmount;
          //   _this.maxAmount = _maxAmount-1;
          // });
          // window.HASH_CONTRACT.getGameRewards().call().then((res)=>{
          //   // console.log(res)
          //   _this.gameRewards = res
          // });
          // //debug
          // _this.getAllowance();
          // _this.getRoads();
          // _this.getLastBet();
          
      }
    }, 15);
    // this.plusBet(this.presetAmount[2],2);
  },
  methods:{
    
    
    
  }
}
</script>
<style lang="less" scoped>
.home {
  margin-top: -20px;
}
.gameWrapper{
  padding: @base-pd;
  // display:flex;
  // flex-wrap: wrap;
  // min-height: calc(100vh - 280px);
  // justify-content: space-between;
  
}
.gameBox{
  width: 100%;
}

</style>
