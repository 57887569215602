<template>
  <div class="game" @click="chooseGame">
    <!-- <div v-if="active" class="hot">
        <img src="@/assets/icons/hottag.svg" alt="">
    </div> -->
    <div class="up flex">
      <div class="icon">
        <img :src="'assets/game_icon_'+id+'/2x.png'" alt="">
      </div>
      <div class="titles flex column">
        <div class="title">{{$t('common.gameNames')[id]}}</div>
        <div class="reward">{{id==2?(reward[0]+'-'+reward[1]):reward[0]}}%</div>
      </div>
    </div>
    <div class="btns">
      <div class="btn" @click="playGame">{{$t('common.winBtn')}}</div>
      <!-- <div v-if="active" class="tag" @click="goRecords">
        <span class="dot"></span>
        <img src="@/assets/icons/tag.svg" alt="">
      </div> -->
      <div class="tag" @click="goRecords">
        <img src="@/assets/images/history_icon.png" alt="">
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Game',
  data(){
    return {
      // active:false
    }
  },
  props:{
    reward:{
      type: Array
    },
    id:{
      type: Number
    },
    active:{
      type: Boolean,
      default: false
    }
  },
  methods: {
    chooseGame(){
      this.$store.commit("setCurrentGameId", this.id);
    },
    playGame(){
      this.$store.commit("setCurrentGameId", this.id);

      this.$router.push({name:'game'});
    },
    goRecords(){
      this.$router.push({name:'records'});
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.game {
  // background: @btn-color;
  background-image: url("~@/assets/images/game_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 385px;
  
  padding: 70px 50px 50px 100px;
  margin: 30px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .hot{
    position: absolute;
    top: -10px;right: -22px;
  }
  .up{

    .icon{
      // padding: 0 @base-pd;
      img{
        width: 150px;
        height: 150px;
        // border: 1px solid @bg-color;
        // border-radius: 50%;
      }
    }
    .titles{
      padding-left: 50px;
      align-items: flex-start;
      color: #8c1a1b;
      .title{
        font-size: 30px;
      }
      .reward{
        font-size: 50px;
        font-weight: 700;
        scale: 1.1;
      }
    }
  }
  
  
  .btns{
    font-size: 32px;
    // color: #e4543b;
    // padding: @base-pd @base-pd 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    .btn{
      background: linear-gradient(to bottom, #e7603f, #e14737);
      // box-shadow: 0px 10px 20px 0px @primary-color-shadow;
      color: #fae7d1;
      // font-weight: 700;
      height: 72px;
      line-height: 72px;
      // padding: 20px;
      border-radius: 36px;
      flex: 1;
    }
    .tag{
      position: relative;
      padding-left: 1.5*@base-pd;
      img {
        width: 50px;
      }
      // .dot{
      //   /* 椭圆形 */
      //   width: 10px;
      //   height: 10px;
      //   border-radius: 50%;
      //   background-color: @primary-color;
      //   box-shadow: 0px 10px 20px 0px @primary-color-shadow;
      //   position:absolute;
      //   top:-8px;right:-10px;
      // }
    }
  }
  // .ant-btn-primary{
  //   background: @primary-color;
  //   box-shadow: 0px 10px 20px 0px @primary-color-shadow;
  //   font-size: 12px;
  //   font-weight: 700;
  //   color: @bg-color;
  //   border:0;
  // }
}

</style>
